import React from "react";
import axios from "axios";
import { createBrowserHistory } from "history";

class Search extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      query: "",
      results: {},
      loading: false,
      message: "",
      totalResults: 0,
      totalPages: 0,
      currentPageNo: 0,
    };

    this.cancel = "";
  }

  /**
   * Get the Total Pages count.
   *
   * @param total
   * @param denominator Count of results per page
   * @return {number}
   */
  getPageCount = (total, denominator) => {
    const divisible = 0 === total % denominator;
    const valueToBeAdded = divisible ? 0 : 1;
    return Math.floor(total / denominator) + valueToBeAdded;
  };

  /**
   * Fetch the search results and update the state with the result.
   * Also cancels the previous query before making the new one.
   *
   * @param {int} updatedPageNo Updated Page No.
   * @param {String} query Search Query.
   *
   */
  fetchSearchResults = (updatedPageNo = "", query) => {
    const pageNumber = updatedPageNo ? `&page=${updatedPageNo}` : "";
    const Baseurl = process.env.REACT_APP_base_url;
    const searchUrl = `${Baseurl}search/searchdata?q=${query}${pageNumber}`;
    //const searchUrl = `https://pixabay.com/api/?key=20375628-f1deb54182acd62662c800a2e&q=${query}${pageNumber}`;

    if (this.cancel) {
      this.cancel.cancel();
    }

    this.cancel = axios.CancelToken.source();

    axios
      .get(searchUrl, {
        cancelToken: this.cancel.token,
      })
      .then((res) => {
        const total = res.data.total;
        const totalPagesCount = this.getPageCount(total, 20);
        const resultNotFoundMsg = !res.data.response.length
          ? "There are no more search results. Please try a new search"
          : "";
        this.setState({
          results: res.data.response,
          message: resultNotFoundMsg,
          totalResults: total,
          totalPages: totalPagesCount,
          currentPageNo: updatedPageNo,
          loading: false,
        });
      })
      .catch((error) => {
        if (axios.isCancel(error) || error) {
          this.setState({
            loading: false,
            message: "Failed to fetch the data. Please check network",
          });
        }
      });
  };

  handleOnInputChange = (event) => {
    const query = event.target.value;
    if (!query) {
      this.setState({
        query,
        results: {},
        message: "",
        totalPages: 0,
        totalResults: 0,
      });
    } else {
      this.setState({ query, loading: true, message: "" }, () => {
        this.fetchSearchResults(1, query);
      });
    }
  };

  /**
   * Fetch results according to the prev or next page requests.
   *
   * @param {String} type 'prev' or 'next'
   */
  handlePageClick = (type) => {
    //event.preventDefault();
    const updatePageNo =
      "prev" === type
        ? this.state.currentPageNo - 1
        : this.state.currentPageNo + 1;

    if (!this.state.loading) {
      this.setState({ loading: true, message: "" }, () => {
        this.fetchSearchResults(updatePageNo, this.state.query);
      });
    }
  };

  renderSearchResults = () => {
    const { results } = this.state;
    const history = createBrowserHistory({ forceRefresh: true });
    const redirectToPage = (symbol) => {
      history.push("/stocks/" + symbol);
    };

    if (Object.keys(results).length && results.length) {
      return (
        <div className="header-serch-result">
          {results.map((result, key) => {
            return (
              <div key={key}>
                <p
                  onClick={(e) => redirectToPage(result.symbol)}
                  to={"/stocks/" + result.symbol}
                >
                  <strong>{result.name}</strong>
                </p>
                {/* <span className="badge badge-dark custom-batch ">{result.c} <span className={result.className}> ({result.cp}%) </span></span>
								<p className="customrec"><span>LTP : {result.l}</span> <span>O : {result.op}</span> <span>H : {result.hi}</span> <span>L : {result.lo}</span> </p> */}
              </div>
            );
          })}
        </div>
      );
    }
  };

  render() {
    return (
      <>
        <input
          name="txtSearchComp"
          id="txtSearchComp1"
          autoComplete="off"
          className="form-control searchbox"
          onChange={this.handleOnInputChange}
          autoFocus=""
          type="search"
          placeholder="Type a Company Name to Search"
        />
        <div
          className={
            this.props.userdata
              ? this.props.userdata.mobile
                ? "autocomplete-items-header1"
                : "autocomplete-items-header1"
              : "autocomplete-items-header"
          }
        >
          {this.renderSearchResults()}
        </div>
      </>
    );
  }
}

export default Search;

import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Navbar, Nav, NavDropdown } from "react-bootstrap";
import { createBrowserHistory } from "history";
import PersonOutlineIcon from "@material-ui/icons/PersonOutline";
import "./header.css";
import Search from "./Search";
import { useLocation } from "react-router-dom";
import jwtDecode from "jwt-decode";
import RestService from "../Restservice";
import Loader from "../component/Loader";

const history = createBrowserHistory({
  forceRefresh: true,
});

export default function Header() {
  const userloggedin = localStorage.getItem("userdata")
    ? JSON.parse(localStorage.getItem("userdata"))
    : false;

  const [mobile, setmobile] = useState("");
  const [loading, setLoading] = useState(false);
  const location = useLocation();
  const pathNames = location?.pathname.split("/").join("");
  const tradingLocation = "option-chainoption-trading-terminal";
  const tradingTerminal = "trading-terminal";

  // Extracts pathname property(key) from an object
  const { pathname } = useLocation();
  // Automatically scrolls to top whenever pathname changes
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  const logout = () => {
    localStorage.clear();
    setmobile("");
    history.push("/");
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    const userdata = localStorage.getItem("userdata")
      ? JSON.parse(localStorage.getItem("userdata"))
      : "";
    if (userdata) {
      setmobile(userdata.mobile ? userdata.mobile : "");
    } else {
      const script = document.createElement("script");
      script.src = "https://accounts.google.com/gsi/client";
      script.onload = initializeGSI();
      script.async = true;
      document.querySelector("body").appendChild(script);
    }
  }, []);

  if (pathNames === tradingLocation) {
    return <></>;
  }
  if (pathNames === tradingTerminal) {
    //return <></>;
  }

  /************************* Implementation of one tap************************************/
  const google = window.google;

  const initializeGSI = () => {
    google?.accounts?.id?.initialize({
      client_id:
        "588082685737-n3uuqrqqfb6ttgpcuvpnm5ce5dd3stku.apps.googleusercontent.com",
      callback: handleResponse,
    });
    google?.accounts?.id.prompt((notification) => {
      console.log(notification);
    });
  };

  const handleResponse = (response) => {
    const token = response.credential;
    const decodedToken = jwtDecode(token);
    //console.log('token',decodedToken)
    let fdata = new FormData();
    fdata.append("googleId", decodedToken?.aud);
    fdata.append("name", decodedToken?.name);
    fdata.append("email", decodedToken?.email);
    RestService.GoogleLoginService(function (res) {
      const respo = JSON.parse(res);
      setLoading(false);
      if (respo.data.status === 1) {
        localStorage.setItem(
          "userdata",
          JSON.stringify(respo.data.userDetails, null)
        );
        history.push("/");
      }
    }, fdata);
  };

  return (
    <>
      {loading ? <Loader /> : ""}
      <div className="header"></div>
      <div className="trade_navigation">
        <div className="container">
          <div className="navbar nav_bottom">
            <div className="navbar-header nav_2">
              <div>
                <Link to="/" area-lable="Money tree Robo Logo">
                  <img
                    src={require("../images/moneytree-footer-logo.png").default}
                    alt="Moneytree"
                    prop=""
                    width="60"
                  />
                  <span>Money Tree</span> Robo
                  <i>Stock Screener </i>
                </Link>
              </div>
            </div>
            <div className="leftandright1">
              <Search userdata={userloggedin} />
            </div>
            <div className="leftandright">
              <div className="menubar-right">
                <Navbar expand="lg">
                  <Navbar.Toggle
                    data-bs-toggle="collapse"
                    data-bs-target="#basic-navbar-nav"
                    aria-controls="basic-navbar-nav"
                    aria-expanded="false"
                  />
                  <Navbar.Collapse
                    id="basic-navbar-nav"
                    className="collapse navbar-collapse"
                  >
                    <Nav className="">
                      <Link to="/intraday-screener" className="nav-link">
                        Screener
                      </Link>
                      <Link to="/how-to-use" className="nav-link">
                        How to use
                      </Link>
                      <Link to="/stock-market-today/" className="nav-link">
                        Market Activity
                      </Link>
                      <Link to="/premium" className="nav-link">
                        Premium
                      </Link>
                      <Link to="/subscription-feature" className="nav-link">
                        Subscription
                      </Link>
                      <Link to="/investing" className="nav-link">
                        Articles
                      </Link>
                    </Nav>
                  </Navbar.Collapse>
                </Navbar>
              </div>

              <div className="w3ls_header_top1">
                <NavDropdown
                  title={<PersonOutlineIcon />}
                  id="collasible-nav-dropdown"
                >
                  <Link to="/nifty-heatmap/" className="dividerMenu">
                    <i className="fa fa-map mr-1"></i> Heat Map
                  </Link>
                  <Link
                    to="/intrinsic-value-calculator"
                    className="dividerMenu"
                  >
                    <i
                      className="fa fa-chain-broken mr-1"
                      aria-hidden="true"
                    ></i>
                    Intrinsic Calculator
                  </Link>
                  <Link to="/nse-option-chain-data/" className="dividerMenu">
                    <i
                      className="fa fa-chain-broken mr-1"
                      aria-hidden="true"
                    ></i>
                    Option Chain
                  </Link>
                  <Link to="/futures-and-options" className="dividerMenu">
                    <i className="fa fa-dashboard mr-1" aria-hidden="true"></i>
                    Future Dashboard
                  </Link>
                  <Link to="/trading-terminal/" className="dividerMenu">
                    <i className="fa fa-terminal mr-1" aria-hidden="true"></i>
                    Trading Terminal
                  </Link>
                  <Link to="/faq" className="dividerMenu">
                    <i
                      className="fa fa-question-circle  mr-1"
                      aria-hidden="true"
                    ></i>
                    FAQ
                  </Link>
                  <Link to="/video-course" className="dividerMenu">
                    <i
                      className="fa fa-file-video-o  mr-1"
                      aria-hidden="true"
                    ></i>
                    Video Tutorial
                  </Link>
                  <Link to="contactus" className="dividerMenu">
                    <i
                      className="fa fa-address-book mr-1"
                      aria-hidden="true"
                    ></i>
                    Contact Us
                  </Link>
                  <Link to="/premium-services" className="dividerMenu">
                    <i className="fa fa-plane mr-1" aria-hidden="true"></i>
                    Premium Services
                  </Link>
                  <Link to="/support" className="dividerMenu">
                    <i className="fa fa-support mr-1"></i> Support
                  </Link>
                  {mobile ? (
                    <>
                      <Link to="/back-testing/" className="dividerMenu">
                        <i className="fa fa-list mr-1" aria-hidden="true"></i>
                        Backtest
                      </Link>
                      <Link to="/dashboard" className="dividerMenu">
                        <i
                          className="fa fa-dashboard mr-1"
                          aria-hidden="true"
                        ></i>
                        Dashboard
                      </Link>
                      <Link to="/watchlist" className="dividerMenu">
                        <i
                          className="fa fa-clock-o mr-1"
                          aria-hidden="true"
                        ></i>
                        Watchlist
                      </Link>
                      <Link to="/portfolio" className="dividerMenu">
                        <i
                          className="fa fa-briefcase mr-1"
                          aria-hidden="true"
                        ></i>
                        Portfolio
                      </Link>
                      <Link to="/subscription">
                        <i
                          className="fa fa-credit-card mr-1"
                          aria-hidden="true"
                        ></i>
                        Subscripton
                      </Link>

                      <NavDropdown.Item className="logout" onClick={logout}>
                        <i
                          className="fa fa-sign-out mr-1"
                          aria-hidden="true"
                        ></i>
                        Logout
                      </NavDropdown.Item>
                    </>
                  ) : (
                    <>
                      <Link to="/login/" className="dividerMenu">
                        <i
                          className="fa fa-sign-in mr-1"
                          aria-hidden="true"
                        ></i>
                        Login
                      </Link>
                      <Link to="/registration">
                        <i
                          className="fa fa-sign-in mr-1"
                          aria-hidden="true"
                        ></i>
                        Sign up
                      </Link>
                    </>
                  )}
                </NavDropdown>
              </div>
            </div>
          </div>
        </div>

        <div className="w3ls_header_top">
          <div className="container">
            <div className="w3l_header_left">
              <ul className="w3layouts_header">
                <li className="w3layouts_header_list">
                  <Link to="/video-course">Video Tutorial</Link>
                </li>
              </ul>
            </div>

            <div className="w3l_header_right">
              <ul className="w3layouts_header">
                {mobile && (
                  <li className="w3layouts_header_list">
                    <Link to="/back-testing/">Backtest</Link>
                    <i>|</i>
                  </li>
                )}
                <li className="w3layouts_header_list">
                  <Link to="/nifty-heatmap/">Heat Map</Link>
                  <i>|</i>
                </li>
                <li className="w3layouts_header_list">
                  <Link to="/intrinsic-value-calculator/">
                    Intrinsic Calculator
                  </Link>
                  <i>|</i>
                </li>
                <li className="w3layouts_header_list">
                  <Link to="/nse-option-chain-data/">Option Chain</Link>
                  <i>|</i>
                </li>
                <li className="w3layouts_header_list">
                  <Link to="/futures-and-options">Future Dashboard</Link>
                  <i>|</i>
                </li>
                <li className="w3layouts_header_list">
                  <Link to="/trading-terminal/">Trading Terminal</Link>
                  <i>|</i>
                </li>
              </ul>
              {/* <h2>
                <i
                  className="glyphicon glyphicon-earphone"
                  aria-hidden="true"
                ></i>
                +(91) 8800109358
              </h2> 
              <i>|</i> */}
              {mobile ? (
                <>
                  <NavDropdown
                    className="loggedin_right_menu"
                    title={userloggedin.user_name}
                    id="collasible-nav-dropdown"
                  >
                    <Link
                      disabled
                      to="/my-account"
                      className="logout dropdown-item"
                    >
                      My Account
                    </Link>
                    <Link
                      disabled
                      to="/my-account/learning"
                      className="logout dropdown-item"
                    >
                      My Learning
                    </Link>
                    <Link
                      disabled
                      to="/dashboard"
                      className="logout dropdown-item"
                    >
                      Dashboard
                    </Link>
                    <Link to="/watchlist" className="logout dropdown-item">
                      Watchlist
                    </Link>
                    <Link to="/portfolio" className="logout dropdown-item">
                      Portfolio
                    </Link>
                    <Link
                      to="/subscription-feature"
                      className="logout dropdown-item"
                    >
                      Subscripton
                    </Link>
                    <Link
                      to="/trading-terminal"
                      className="logout dropdown-item"
                    >
                      Trading Terminal
                    </Link>
                    <Link
                      to="/premium-services"
                      className="logout dropdown-item"
                    >
                      Premium Services
                    </Link>
                    <Link to="/support" className="logout dropdown-item">
                      Support
                    </Link>
                    <Link to="/investing" className="logout dropdown-item">
                      Articles
                    </Link>
                    <NavDropdown.Item className="logout" onClick={logout}>
                      Logout
                    </NavDropdown.Item>
                  </NavDropdown>
                  <div></div>
                  {/* <Link to='logout' >Logout</Link> */}
                </>
              ) : (
                <>
                  <Link to="/premium-services">
                    Premium Services<i>|</i>
                  </Link>
                  <Link to="/login/" data-toggle="modal" data-target="#myModal">
                    Login<i>/</i>
                  </Link>
                  <Link to="/registration">Sign up</Link>
                </>
              )}
            </div>

            <div className="clearfix"> </div>
          </div>
        </div>
      </div>
    </>
  );
}
